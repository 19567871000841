.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
}

/* @media only screen and (max-width: 768px) {
  .App {
    display: grid;
    grid-template: 1fr 1fr 1fr 1fr 1fr;
  }
  
} */