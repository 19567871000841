

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 30px;
        width: 100%;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-self: flex-end;
        display: flex;
    }
    
    .icon {
        display: flex;
        width: 40px;
        height: auto;
        align-self: flex-end;
        margin: 0 10px 0 10px;
    }
} 

@media only screen and (max-width: 768px) {

    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 30px;
        width: 100%;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
        align-self: flex-end;
        display: flex;
    }

    .icon {
        display: flex;
        width: 40px;
        height: auto;
        align-self: flex-end;
        margin: 0 10px 0 10px;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 30px;
        width: 100%;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-self: flex-end;
        display: flex;
    }

    .icon {
        display: flex;
        width: 40px;
        height: auto;
        align-self: flex-end;
        margin: 0 10px 0 10px;
    }

}