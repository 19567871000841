/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style-position: inside;
    }

    .project-image-container {
        height: 250px;
        width: auto;
        padding: 0 1em 0 1em;
    }
    .project-item-container {
        display: flex;
        flex-direction: column;
        padding: 50px 10%;
        background-color: #F5F6FA;
    }
    .project-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .white-background {
        background-color: white;
    }
    .colored-background {
        background-color: #F5F6FA;
    }
    .project-item h1 {
        text-align: left;
    }
    .project-image-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project-screenshot {
        height: 500px;
        width: auto;
        align-self: center;
    }
    .project-description-container {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        text-align: left;
    }

    .project-one-description-container {
        padding-left: 20px;
    }
    .project-two-description-container {
        padding-right: 20px;
    }

    .project-item :hover{
        cursor: pointer;
    }
    .logo {
        height: auto;
        width: 100px;
    }
    .tech-used-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .tech-used-icon {
        width: auto;
        height: 50px;
        border-radius: 15px;
        margin-right: 1em;
        padding-bottom: 1em;
    }

    .google-play-badge-container {
        padding-top: 10px;
    }

    .tech-stack-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 50px;
    }
    .tech-stack-item {
        padding-right: 2em;
    }
    .project-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2em;
    }
    .github-group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;
    }
    .github-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 768px) {

    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style-position: inside;
    }

    .project-image-container {
        height: 250px;
        width: auto;
        padding: 0 1em 0 1em;
    }

    .project-item-container {
        background-color: #F5F6FA;
    }

    .project-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F6FA;
    }

    .colored-background {
        background-color: #F5F6FA;
    }
    .project-item h1 {
        text-align: left;
    }

    .project-image-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0px;
    }
    .project-screenshot {
        height: 500px;
        width: auto;
        align-self: center;
    }
    .project-description-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        font-size: 1.5rem;
        text-align: center;
        list-style: none;
    }
    .project-description-container h1 {
        text-align: center;
    }

    .project-one-description-container {
        padding: 0 1rem;
    }
    .project-two-description-container {
        padding: 0 1rem;
    }

    .project-item :hover{
        cursor: pointer;
    }
    .logo {
        height: auto;
        width: 100px;
    }
    .tech-used-icon-container {
        display: block;
    }
    .tech-used-icon {
        width: auto;
        height: 50px;
        border-radius: 15px;
        margin-right: 1em;
        padding-bottom: 1em;
    }

    .google-play-badge-container {
        padding-top: 10px;
    }

    .tech-stack-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 50px;
    }
    .github-group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;
    }
    .github-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .project-reverse {
        flex-direction: column-reverse;
    }
    .project-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
    }
    .white-background {
        background-color: white;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {

    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style-position: inside;
    }

    .project-image-container {
        height: 250px;
        width: auto;
        padding: 0 1em 0 1em;
    }

    .project-item-container {
        background-color: #F5F6FA;
    }

    .project-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F6FA;
    }

    .colored-background {
        background-color: #F5F6FA;
    }
    .project-item h1 {
        text-align: left;
    }

    .project-image-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0px;
    }
    .project-screenshot {
        height: 500px;
        width: auto;
        align-self: center;
    }
    .project-description-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        font-size: 1.5rem;
        text-align: center;
        list-style: none;
    }
    .project-description-container h1 {
        text-align: center;
    }

    .project-one-description-container {
        padding: 0 1rem;
    }
    .project-two-description-container {
        padding: 0 1rem;
    }

    .project-item :hover{
        cursor: pointer;
    }
    .logo {
        height: auto;
        width: 100px;
    }
    .tech-used-icon-container {
        display: block;
    }
    .tech-used-icon {
        width: auto;
        height: 50px;
        border-radius: 15px;
        margin-right: 1em;
        padding-bottom: 1em;
    }

    .google-play-badge-container {
        padding-top: 10px;
    }

    .tech-stack-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
    }
    .github-group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;
    }
    .github-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .project-reverse {
        flex-direction: column-reverse;
    }
    .project-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
    }
    .white-background {
        background-color: white;
    }
}