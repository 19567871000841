

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 30px;
        width: 100%;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-self: flex-end;
        display: flex;
    }
    
    .icon {
        display: flex;
        width: 40px;
        height: auto;
        align-self: flex-end;
        margin: 0 10px 0 10px;
    }
} 

@media only screen and (max-width: 768px) {

    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 30px;
        width: 100%;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
        align-self: flex-end;
        display: flex;
    }

    .icon {
        display: flex;
        width: 40px;
        height: auto;
        align-self: flex-end;
        margin: 0 10px 0 10px;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 30px;
        width: 100%;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-self: flex-end;
        display: flex;
    }

    .icon {
        display: flex;
        width: 40px;
        height: auto;
        align-self: flex-end;
        margin: 0 10px 0 10px;
    }

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .hero-container {
        display: flex;
        background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(/static/media/desk-hero.cbbc0d12.png);
        opacity: 1;
        height: 100vh;
        width: auto;
        background-size: cover;
        background-position-x: center;
        flex-direction: column;
    }

    .header-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        height: 100%;
        padding: 10px 0 10px 0;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-bottom: 15%;
    }
    .header-text {
        font-size: 5em;
        color: white;
    }
    .white-text {
        color: #c7a779;
    }
    .badge {
        display: flex;
        width: 200px;
        height: auto;
    }
    .no-style{
        text-decoration: none;
    }
    .download-cv-badge-container {
        padding-bottom: 1rem;
    }
    .download-cv-badge-container-background {
        background-color: #dc0f4a;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .download-cv-badge-text {
        color: white;
        padding: 0 10px 0 10px;
    }
    .github-text {
        padding-left: 1em;
    }
}

@media only screen and (max-width: 767px) {
    .hero-container {
        display: flex;
        background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(/static/media/desk-hero.cbbc0d12.png);
        opacity: 1;
        height: 100vh;
        width: auto;
        background-size: cover;
        flex-direction: column;
        background-position-x: center;
    }

    .header-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        height: 100%;
        padding: 10px 0 10px 0;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-bottom: 15%;
    }
    .header-text {
        font-size: 3em;
        color: white;
    }
    .white-text {
        color: #c7a779;
    }
    .badge {
        display: flex;
        width: 200px;
        height: auto;
    }
    .no-style{
        text-decoration: none;
    }
    .download-cv-badge-container {
        padding-bottom: 1rem;
    }
    .download-cv-badge-container-background {
        background-color: #dc0f4a;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .download-cv-badge-text {
        color: white;
        padding: 0 10px 0 10px;
    }
    .github-text {
        padding-left: 1em;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
    .hero-container {
        display: flex;
        background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(/static/media/desk-hero.cbbc0d12.png);
        opacity: 1;
        height: 100vh;
        width: auto;
        background-size: cover;
        flex-direction: column;
    }

    .header-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        height: 100%;
        padding: 10px 0 10px 0;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-bottom: 15%;
    }
    .header-text {
        font-size: 5em;
        color: white;
    }
    .white-text {
        color: #c7a779;
    }
    .badge {
        display: flex;
        width: 200px;
        height: auto;
    }
    .no-style{
        text-decoration: none;
    }
    .download-cv-badge-container {
        padding-bottom: 1rem;
    }
    .download-cv-badge-container-background {
        background-color: #dc0f4a;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .download-cv-badge-text {
        color: white;
        padding: 0 10px 0 10px;
    }
    .github-text {
        padding-left: 1em;
    }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import './reset.css'; */


.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
}

/* @media only screen and (max-width: 768px) {
  .App {
    display: grid;
    grid-template: 1fr 1fr 1fr 1fr 1fr;
  }
  
} */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .skills-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .skills-items-container {
        display: flex;
        align-self: center;
        width: 100%;
        background-color: white;
        justify-content: space-around;
        flex-direction: row;
        padding-bottom: 20px;
    }

    .skill-item {
        font-weight: bold;
        font-size: 1em;
        color: black;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo {
        height: auto;
        width: 100px;
        align-self: center;
        justify-self: center;
        padding-top: 1rem;
    }
}

@media only screen and (max-width: 768px) {

    .skills-container {
        display: none;
    }

}

@media screen and (min-width: 767px) and (max-width: 991px) {

    .skills-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .skills-items-container {
        display: flex;
        align-self: center;
        width: 100%;
        background-color: white;
        justify-content: space-around;
        flex-direction: row;
        /*border-bottom: black 1px solid;*/
        padding-bottom: 20px;
    }

    .skill-item {
        font-weight: bold;
        font-size: 1em;
        color: black;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo {
        height: auto;
        width: 100px;
        align-self: center;
        justify-self: center;
        padding-top: 1rem;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style-position: inside;
    }

    .project-image-container {
        height: 250px;
        width: auto;
        padding: 0 1em 0 1em;
    }
    .project-item-container {
        display: flex;
        flex-direction: column;
        padding: 50px 10%;
        background-color: #F5F6FA;
    }
    .project-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .white-background {
        background-color: white;
    }
    .colored-background {
        background-color: #F5F6FA;
    }
    .project-item h1 {
        text-align: left;
    }
    .project-image-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project-screenshot {
        height: 500px;
        width: auto;
        align-self: center;
    }
    .project-description-container {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        text-align: left;
    }

    .project-one-description-container {
        padding-left: 20px;
    }
    .project-two-description-container {
        padding-right: 20px;
    }

    .project-item :hover{
        cursor: pointer;
    }
    .logo {
        height: auto;
        width: 100px;
    }
    .tech-used-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .tech-used-icon {
        width: auto;
        height: 50px;
        border-radius: 15px;
        margin-right: 1em;
        padding-bottom: 1em;
    }

    .google-play-badge-container {
        padding-top: 10px;
    }

    .tech-stack-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 50px;
    }
    .tech-stack-item {
        padding-right: 2em;
    }
    .project-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2em;
    }
    .github-group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;
    }
    .github-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 768px) {

    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style-position: inside;
    }

    .project-image-container {
        height: 250px;
        width: auto;
        padding: 0 1em 0 1em;
    }

    .project-item-container {
        background-color: #F5F6FA;
    }

    .project-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F6FA;
    }

    .colored-background {
        background-color: #F5F6FA;
    }
    .project-item h1 {
        text-align: left;
    }

    .project-image-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0px;
    }
    .project-screenshot {
        height: 500px;
        width: auto;
        align-self: center;
    }
    .project-description-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        font-size: 1.5rem;
        text-align: center;
        list-style: none;
    }
    .project-description-container h1 {
        text-align: center;
    }

    .project-one-description-container {
        padding: 0 1rem;
    }
    .project-two-description-container {
        padding: 0 1rem;
    }

    .project-item :hover{
        cursor: pointer;
    }
    .logo {
        height: auto;
        width: 100px;
    }
    .tech-used-icon-container {
        display: block;
    }
    .tech-used-icon {
        width: auto;
        height: 50px;
        border-radius: 15px;
        margin-right: 1em;
        padding-bottom: 1em;
    }

    .google-play-badge-container {
        padding-top: 10px;
    }

    .tech-stack-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 50px;
    }
    .github-group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;
    }
    .github-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .project-reverse {
        flex-direction: column-reverse;
    }
    .project-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
    }
    .white-background {
        background-color: white;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {

    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        list-style-position: inside;
    }

    .project-image-container {
        height: 250px;
        width: auto;
        padding: 0 1em 0 1em;
    }

    .project-item-container {
        background-color: #F5F6FA;
    }

    .project-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F6FA;
    }

    .colored-background {
        background-color: #F5F6FA;
    }
    .project-item h1 {
        text-align: left;
    }

    .project-image-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0px;
    }
    .project-screenshot {
        height: 500px;
        width: auto;
        align-self: center;
    }
    .project-description-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        font-size: 1.5rem;
        text-align: center;
        list-style: none;
    }
    .project-description-container h1 {
        text-align: center;
    }

    .project-one-description-container {
        padding: 0 1rem;
    }
    .project-two-description-container {
        padding: 0 1rem;
    }

    .project-item :hover{
        cursor: pointer;
    }
    .logo {
        height: auto;
        width: 100px;
    }
    .tech-used-icon-container {
        display: block;
    }
    .tech-used-icon {
        width: auto;
        height: 50px;
        border-radius: 15px;
        margin-right: 1em;
        padding-bottom: 1em;
    }

    .google-play-badge-container {
        padding-top: 10px;
    }

    .tech-stack-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
    }
    .github-group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2em;
    }
    .github-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .project-reverse {
        flex-direction: column-reverse;
    }
    .project-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
    }
    .white-background {
        background-color: white;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        height: 100px;
        align-items: center;
        justify-content: center;
    }
    .footer-react-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
    }
    .footer-icon-react {
        height: auto;
        width: 2em;
    }
    .footer-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 1em;
    }
    .cv-circle {
        display: flex;
        border-radius: 100px;
        width: 1em;
        height: 1em;
        padding: 0.5em;
        margin: 0 1em;
        align-items: center;
        justify-content: center;
    }
    .dark-cv-icon {
        border: black 2px solid;
        color: black;
    }
    .light-cv-icon {
        border: white 2px solid;
        color: white;
    }
}

@media only screen and (max-width: 767px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        height: 100px;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .footer-react-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
    }
    .footer-icon-react {
        height: auto;
        width: 2em;
    }
    .footer-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 1em;
    }
    .cv-circle {
        display: flex;
        border-radius: 100px;
        width: 1em;
        height: 1em;
        padding: 0.5em;
        margin: 0 1em;
        align-items: center;
        justify-content: center;
    }
    .dark-cv-icon {
        border: black 2px solid;
        color: black;
    }
    .light-cv-icon {
        border: white 2px solid;
        color: white;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        height: 100px;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .footer-react-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
    }
    .footer-icon-react {
        height: auto;
        width: 2em;
    }
    .footer-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 1em;
    }
    .cv-circle {
        display: flex;
        border-radius: 100px;
        width: 1em;
        height: 1em;
        padding: 0.5em;
        margin: 0 1em;
        align-items: center;
        justify-content: center;
    }
    .dark-cv-icon {
        border: black 2px solid;
        color: black;
    }
    .light-cv-icon {
        border: white 2px solid;
        color: white;
    }

}
