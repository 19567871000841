/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        height: 100px;
        align-items: center;
        justify-content: center;
    }
    .footer-react-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
    }
    .footer-icon-react {
        height: auto;
        width: 2em;
    }
    .footer-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 1em;
    }
    .cv-circle {
        display: flex;
        border-radius: 100px;
        width: 1em;
        height: 1em;
        padding: 0.5em;
        margin: 0 1em;
        align-items: center;
        justify-content: center;
    }
    .dark-cv-icon {
        border: black 2px solid;
        color: black;
    }
    .light-cv-icon {
        border: white 2px solid;
        color: white;
    }
}

@media only screen and (max-width: 767px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        height: 100px;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .footer-react-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
    }
    .footer-icon-react {
        height: auto;
        width: 2em;
    }
    .footer-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 1em;
    }
    .cv-circle {
        display: flex;
        border-radius: 100px;
        width: 1em;
        height: 1em;
        padding: 0.5em;
        margin: 0 1em;
        align-items: center;
        justify-content: center;
    }
    .dark-cv-icon {
        border: black 2px solid;
        color: black;
    }
    .light-cv-icon {
        border: white 2px solid;
        color: white;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        height: 100px;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .footer-react-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
    }
    .footer-icon-react {
        height: auto;
        width: 2em;
    }
    .footer-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 1em;
    }
    .cv-circle {
        display: flex;
        border-radius: 100px;
        width: 1em;
        height: 1em;
        padding: 0.5em;
        margin: 0 1em;
        align-items: center;
        justify-content: center;
    }
    .dark-cv-icon {
        border: black 2px solid;
        color: black;
    }
    .light-cv-icon {
        border: white 2px solid;
        color: white;
    }

}